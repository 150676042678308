@import '@angular/material/theming';
@import './azure.palette.scss';
@import './black.palette.scss';
@import './green.palette.scss';
@import './orange.palette.scss';
@import './red.palette.scss';
@import './purple.palette.scss';
@include mat-core();

//$primary: mat-palette($mat-blue);
$primary: mat-palette($black-palette, 600);
$accent: mat-palette($mat-pink, A200, A100, A400);
$warn: mat-palette($mat-red);
$theme: mat-light-theme($primary, $accent, $warn);

.azure-theme {
  $azure-primary: mat-palette($azure-palette, 400);
  color: black;
  $primary: $azure-primary;
  $accent: $azure-primary;
  $warn: mat-palette($mat-red);
  $azure-theme: mat-light-theme($primary, $accent, $warn);

  @include angular-material-theme($azure-theme);
}

.black-theme {
  $black-primary: mat-palette($black-palette, 600);
  color: $light-primary-text;
  $primary: $black-primary;
  $accent: $black-primary;
  $warn: mat-palette($mat-red);
  $black-theme: mat-dark-theme($primary, $accent, $warn);
  $background-color: map-get($black-palette, background);
  $background: map-get($theme, background);
  $background: map-merge($background, (background: $background-color));

  @include angular-material-theme(map_merge($black-theme, (background: $background)));
}

.grey-theme {
  $black-primary: mat-palette($black-palette, 600);
  color: $light-primary-text;
  $primary: $black-primary;
  $accent: $black-primary;
  $warn: mat-palette($mat-red);
  $grey-theme: mat-light-theme($primary, $accent, $warn);

  $background-color: map-get($black-palette, foreground);
  $background: map-get($theme, background);
  $background: map-merge($background, (background: $background-color));

  @include angular-material-theme(map_merge($grey-theme, (background: $background)));
}

.green-theme {
  $green-primary: mat-palette($green-palette, 500);
  color: black;
  $primary: $green-primary;
  $accent: $green-primary;
  $warn: mat-palette($mat-red);
  $green-theme: mat-light-theme($primary, $accent, $warn);

  @include angular-material-theme($green-theme);
}

.orange-theme {
  $orange-primary: mat-palette($orange-palette, 400);
  color: black;
  $primary: $orange-primary;
  $accent: $orange-primary;
  $warn: mat-palette($mat-red);
  $orange-theme: mat-light-theme($primary, $accent, $warn);

  @include angular-material-theme($orange-theme);
}

.red-theme {
  $red-primary: mat-palette($red-palette, 400);
  color: black;
  $primary: $red-primary;
  $accent: $red-primary;
  $warn: mat-palette($mat-red);
  $red-theme: mat-light-theme($primary, $accent, $warn);

  @include angular-material-theme($red-theme);
}

.purple-theme {
  $purple-primary: mat-palette($purple-palette, 300);
  color: black;
  $primary: $purple-primary;
  $accent: $purple-primary;
  $warn: mat-palette($mat-red);
  $purple-theme: mat-light-theme($primary, $accent, $warn);

  @include angular-material-theme($purple-theme);
}

@include angular-material-theme($theme);