/* You can add global styles to this file, and also import other style files */
@import './themes/theme.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";
// Preserve order of import to be able to customize alert.
@import "sweetalert2/src/variables.scss";

$swal2-background: black;
$swal2-color: white;
$swal2-border: solid white 1px;

$swal2-button-padding: .625em 1.1em;
$swal2-button-box-shadow: none;

$swal2-confirm-button-border: 1px solid #808080;
$swal2-confirm-button-background-color: #000;
$swal2-deny-button-background-color: #000;
$swal2-deny-button-border: 1px solid #808080;

$swal2-confirm-button-focus-box-shadow: none;
$swal2-deny-button-focus-box-shadow: none;

$swal2-confirm-button-font-size: 12px;
$swal2-deny-button-font-size: 12px;

$swal2-button-darken-hover: #1A1A1A;

$xsmall: 599.98px;

@import 'sweetalert2/src/sweetalert2.scss';

:root {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.vx-input {
  border-radius: 10px;
  background-color: rgb(226, 226, 226);
  border: none;
  padding: 10px;
  margin-bottom: 1.5vh;
}

.vx-input-invalid {
  border-color: red;
  border-width: 2px;
  border-style: solid;
}

.vx-btn {
  border-radius: 10px;
  background-color: black;
  color: white;
  padding: 8px 50px;
  margin-bottom: 1.5vh;
  border-style: none;
}

.vx-btn-invalid {
  color: gray;
}

.spinner-container {
  position: absolute;
  top: 40%;
  left: 45%;
}

.ng-select.custom-multiselect {
  scrollbar-color: rgba(255, 255, 255, 0.38) !important;
  scrollbar-width: thin !important;

  ::-webkit-scrollbar {
    background: black;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.38);
    width: 10px;
    border-radius: 10px;
  }
}

.ng-select.custom-multiselect .ng-select-container {
  background-color: black;
}

.ng-select.custom-multiselect .ng-option {
  color: white;
  background-color: black;
}

.ng-select.custom-multiselect .scrollable-content {
  background-color: black;
  color: white;
}

.ng-select.custom-multiselect .ng-option:hover {
  background-color: #2D2D2D;
  color: white;
}

.ng-select.custom-multiselect .ng-option-select {
  background-color: white;
  color: black;
}

.ng-select.custom-multiselect .ng-input input {
  color: white !important;
}

.ng-select.custom-multiselect .ng-value {
  background-color: transparent !important;
  border-color: white !important;
  border-width: 1px !important;
  color: white !important;
  border-style: solid;
}

.ng-select.custom-multiselect .ng-placeholder {
  padding-bottom: 0 !important;
}

.ng-select.custom-multiselect .ng-value-container {
  padding-bottom: 0 !important;
}

.ng-select.custom-multiselect .ng-dropdown-panel-items {
  background-color: black;
}

/* Collapsed sidebar menu */
.navbar-toggle-size{
  margin-top: 19px;
  margin-bottom: 19px;
  border: none;
  background-color: transparent;
  padding-right: 12px;
}

.navbar-toggle-size i {
  font-size: 28px;
}

.main-panel .navbar-container{
  padding-left: 0;
} 

.sidebar-collapsed .sidebar-menu-labels{
  display: none;
}

.sidebar-collapsed .sidebar .nav i{
  float:none;
}
.sidebar-collapsed .sidebar, .sidebar-collapsed .sidebar .sidebar-wrapper{
  width: 90px;
}

.sidebar-collapsed .main-panel{
  width: calc(100% - 90px);
}
/* Fin collapsed sidebar menu */